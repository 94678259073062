import { FC } from "react";
import { Link } from "src/components/Link";
import { Section } from "src/components/Section";
import { ContentOverlay } from "src/components/ContentOverlay";
import { ISectionWithImage } from "src/@types";

interface Props extends ISectionWithImage {}

export const ContactUs: FC<Props> = ({ image, phone }) => (
  <ContentOverlay bg={image} bgPositionClassName="object-center-30">
    <Section
      className="wrapper gl:py-41.25"
      titleClassName="mb-5 text-yellow-base"
      title="Contact Us for Appliance Maintenance Services"
    >
      <p className="mb-10 md:text-center text-xl font-manrope font-medium">
        Ensure your appliances stay in peak condition with&nbsp;
        <span className="text-yellow-base">Smartchoice Service</span>&nbsp;
        expert maintenance.
      </p>

      <p className="mb-10 md:text-center text-xl font-manrope font-bold">
        Call us at &nbsp;
        <Link href={phone?.href} className="text-yellow-base hover:underline">
          {phone?.label}
        </Link>
        &nbsp; to schedule your appliance maintenance appointment today.
      </p>

      <p className="md:text-center text-xl font-manrope">
        Trust Smartchoice Service to keep your appliances running smoothly with
        our dedicated maintenance services.
      </p>
    </Section>
  </ContentOverlay>
);
