import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { getCurrentNewsTipPostAsync } from "src/redux/currentNewsTipsPost/actions";
import {
  selectorCurrentNewsTipsPost,
  selectorCurrentNewsTipsPostIsLoading,
} from "src/redux/currentNewsTipsPost/selectors";
import { HeroPost } from "src/page-components/NewsTips/Post/HeroPost";
import { TableOfContent } from "src/page-components/NewsTips/Post/TableOfContent";
import { PostInfo } from "src/page-components/NewsTips/Post/PostInfo";
import { NewsTips } from "src/page-components/Home/NewsTips";
import { NewsTipsForm } from "src/page-components/NewsTips/Post/NewsTipsForm";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { Loader } from "src/components/Loader";
import { SCREEN_BREAKPOINTS } from "src/constants/screenBreakpoints";
import { Sizes } from "src/@types/sizes";

const NewsTipsPost = () => {
  const { slug } = useParams();

  const sectionRef = useRef(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCurrentNewsTipPostAsync(slug));
  }, [slug, dispatch]);

  const newsTipsPostData = useAppSelector(selectorCurrentNewsTipsPost);
  const isLoading = useAppSelector(selectorCurrentNewsTipsPostIsLoading);

  const { date, description, title, steps, details, banner, img } =
    newsTipsPostData || {};



  const isLGScreenBreakpoint = useMediaQuery({
    minWidth: SCREEN_BREAKPOINTS.LG,
  });

  return (
    <PageWrapper isShownMaps={false}>
      {isLoading ? (
        <div className="flex justify-center items-center min-h-96">
          <Loader size={Sizes.L} />
        </div>
      ) : (
        <>
          <HeroPost
            heroImage={banner || img}
            title={title}
            date={date}
            description={description}
            sectionRef={sectionRef}
            steps={steps}
          />

          <div ref={sectionRef} className="container relative">
            <div className="flex flex-col-reverse lg:flex-row">
              <div className="flex-grow">
                <PostInfo steps={steps} details={details} />
              </div>

              {!isLGScreenBreakpoint && (
                <div className="flex-shrink-0 lg:pt-16">
                  <TableOfContent steps={steps} />
                </div>
              )}
            </div>

            <NewsTipsForm />

            <NewsTips />
          </div>
        </>
      )}
    </PageWrapper>
  );
};

export default NewsTipsPost;
