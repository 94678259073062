import { ICard } from "src/@types/card";

export const MEMBERSHIPS_ADVANTAGES_CARDS: ICard[] = [
  {
    id: crypto.randomUUID(),
    title: "15% Off Labor for Repairs",
    description:
      "As a member, you’ll receive a 15% discount on labor for any repairs needed throughout the year. Save money while keeping your HVAC system in top shape.",
  },
  {
    id: crypto.randomUUID(),
    title: "Priority Service",
    description:
      "Enjoy priority scheduling for all your HVAC service needs. No more waiting during peak seasons; we’ve got you covered.",
  },
  {
    id: crypto.randomUUID(),
    title: "Extended Equipment Life",
    description:
      "Regular maintenance helps extend the lifespan of your HVAC system, ensuring it operates efficiently for years to come. Avoid costly replacements and enjoy a reliable system.",
  },
  {
    id: crypto.randomUUID(),
    title: "Peace of Mind",
    description:
      "With our professional team taking care of your HVAC system, you can rest easy knowing your home will be comfortable in any season. Focus on what matters most while we handle the rest.",
  },
];

export const POINT_INSPECTION_CARD = {
  title: "Point Inspection",
  description:
    "Each tune-up includes a detailed 15-point inspection to ensure your system is operating at its best:",
  blocks: [
    {
      id: crypto.randomUUID(),
      title: "Air Conditioning Unit:",
      description: `
        <li>Check thermostat calibration</li>
        <li>Check and replace air filter (customer must provide filter) </li>

        <li>Inspect condenser coil</li>
        <li>Inspect ductwork</li>

        <li>Check refrigerant level</li>
        <li>Measure temperature difference</li>

        <li>Inspect and tighten electrical connections</li>
        <li>Check safety controls</li>

        <li>Lubricate moving parts</li>
        <li>Inspect wiring and connections</li>

        <li>Check condensate drain</li>
        <li>Test start and run capacitors</li>

        <li>Inspect fan motor and blades</li>
        <li>Check for overall system efficiency</li>
        
        <li>Test system controls</li>
        `,
    },
    {
      id: crypto.randomUUID(),
      title: "Heating Unit:",
      description: `
        <li>Check thermostat calibration</li>
        <li>Inspect and test flue</li>

        <li>Inspect heat exchanger</li>
        <li>Measure temperature rise</li>

        <li>Test safety controls</li>
        <li>Check for gas leaks</li>

        <li>Inspect burners</li>
        <li>Test ignition system</li>

        <li>Check gas pressure</li>
        <li>Inspect ductwork</li>

        <li>Inspect electrical connections</li>
        <li>Inspect blower components</li>

        <li>Lubricate moving parts</li>
        <li>Check overall system performance</li>

        <li>Check air filter</li>`,
    },
  ],
};
